import { MapPinLine } from 'phosphor-react-sc'
import { Button } from '@/components/atoms/button'
import BlockContent from '@/components/blocks/block-content'
import SafeLink from '@ignition/library/components/atoms/safe-link'
import Text from '@ignition/library/components/atoms/text'
import { JobSurveyProps } from './job-survey'

interface JobApplyCtaProps extends JobSurveyProps {
  onAction: () => void
}

const styles = {
  main: 'relative pt-6 pb-16 sm:pb-24 mt-16 sm:mt-24 mx-auto max-w-7xl',
  gridDivider: 'lg:grid lg:grid-flow-col-dense lg:gap-8',
  textContentDiv: 'px-4 sm:text-center  md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left',
  title: 'text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-5xl',
  summary: 'mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg leading-6 prose-p:text-gray-300',
  actionDiv: 'mt-16 sm:mt-24 lg:col-span-6 lg:mt-0',
  actionBox: 'bg-white sm:mx-auto sm:w-full sm:max-w-md sm:overflow-hidden sm:rounded-lg',
  actionBoxText: 'prose prose-sm sm:prose-lg prose-indigo mx-auto my-6 text-gray-500',
  actionH2: 'text-center sm:text-3xl md:text-4xl mt-8',
  iconLocation: 'inline text-gray-400 mr-2',
  buttonDiv: 'space-y-6 text-center',
  button: 'w-full text-sm sm:text-base justify-center',
  privacyTextDiv: 'border-t-2 border-gray-200 bg-gray-50 px-6 py-6 sm:px-10',
  privacyText: 'text-xs leading-5 text-gray-500',
  privacyLink: 'text-xs leading-5 font-medium text-gray-900 hover:underline',
}

export const JobApplyCta = ({
  title,
  summary,
  jobTitle,
  buttonType,
  location,
  onAction = () => {},
  button,
  privacyHref,
}: JobApplyCtaProps) => {
  const hasSideText = title || summary
  const slug = button?.linkType === 'entry' ? button?.entry?.slug : button?.href
  return (
    <div id="job-apply" className={styles.main}>
      <div className={styles.gridDivider}>
        {hasSideText && (
          <div className={styles.textContentDiv}>
            <div>
              {title && (
                <Text as="h1" className={styles.title}>
                  {title}
                </Text>
              )}
              {summary && <BlockContent blocks={summary} className={styles.summary} />}
            </div>
          </div>
        )}
        <div className={styles.actionDiv}>
          <div className={styles.actionBox}>
            <div className="px-6 py-8 sm:px-10">
              <div className="mt-6">
                <div className={styles.actionBoxText}>
                  <Text as="h2" className={styles.actionH2}>
                    {jobTitle}
                  </Text>
                  {location && (
                    <div className="text-center">
                      <MapPinLine weight="fill" size={24} className={styles.iconLocation} />
                      {location}
                    </div>
                  )}
                </div>
                <div className={styles.buttonDiv}>
                  <Button
                    type={buttonType}
                    href={slug}
                    variant={button?.variant}
                    className={styles.button}
                    onClick={onAction}
                  >
                    <span>{button?.title || 'Online-Bewerbung starten...'} </span>
                  </Button>
                </div>
              </div>
            </div>
            <div className={styles.privacyTextDiv}>
              <p className={styles.privacyText}>
                Mit der Nutzung der Online-Bewerbung stimmen Sie unseren{' '}
                <SafeLink id="privacy-href-job-cta" className={styles.privacyLink} href={privacyHref}>
                  Datenschutzbedingungen
                </SafeLink>{' '}
                zu.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
