import React, { FC } from 'react'
import cx from 'clsx'
import CustomLink from '@/components/blocks/link'
import { ImageAsset } from '@/types/site.interface'
import Text from '@ignition/library/components/atoms/text'
import { twAspectRatio } from '@ignition/library/utils/tailwind-helpers'
import {
  PortableTextBlockComponent,
  PortableTextComponentProps,
  PortableTextTypeComponentProps,
} from '@portabletext/react'
import ImageElement from '../elements/image'

interface SerializerProps {
  isButton?: boolean
  inline?: boolean
  style?: string
  customRatio?: string
  aspectRatio?: string
  asset?: ImageAsset
}

const PhotoSerializer: FC<PortableTextTypeComponentProps<SerializerProps>> = ({ value }) => {
  const { aspectRatio } = value
  return (
    <div
      className={cx(twAspectRatio(value.customRatio), 'h-full w-full relative')}
      style={value.customRatio === 'original' ? { aspectRatio } : undefined}
    >
      <ImageElement image={value as ImageAsset} fill />
    </div>
  )
}

const TextSerializer: FC<PortableTextComponentProps<SerializerProps>> = ({ children, value }) => (
  <Text as={value.style === 'normal' ? 'p' : value?.style}>{children}</Text>
)

export const componentSerializer = {
  block: TextSerializer as PortableTextBlockComponent,
  types: {
    photo: PhotoSerializer,
    horizontalRule: () => <hr />,
  },
  marks: {
    link: CustomLink,
  },
}
