import React, { FC } from 'react'
import SafeLink from '@ignition/library/components/atoms/safe-link'
import { isEmptyObject } from '@ignition/library/utils/object'
import { PortableTextMarkComponentProps } from '@portabletext/react'
import { Button } from '../atoms/button'

const CustomLink: FC<PortableTextMarkComponentProps> = ({ value, children, text, markKey }) => {
  if (!value || isEmptyObject(value)) return null

  const isExternal = value.linkType === 'external' || !!value?.href
  const slug = isExternal ? value?.url || value.href : value.page?.slug
  const target = isExternal && slug && !slug.match('^mailto:|^tel:') ? '_blank' : undefined
  const title = text || children

  return value.isButton ? (
    <Button className="mr-8 mb-5 prose-a:no-underline" type="anchor" variant={value.styles?.variant} href={slug}>
      {title}
    </Button>
  ) : (
    <SafeLink
      id={`${markKey}-${slug}`}
      rel={isExternal ? 'noopener noreferrer' : undefined}
      target={target ? '_blank' : undefined}
      href={slug}
      className="text-primary"
    >
      {title}
    </SafeLink>
  )
}

export default CustomLink
