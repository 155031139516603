import React, { FC } from 'react'
import cx from 'clsx'
import { Blocks } from '@/types/site.interface'
import { PortableText } from '@portabletext/react'
import { componentSerializer } from './block-serializers'

interface BlockContentProps {
  blocks?: Blocks
  className?: string
}

const BlockContent: FC<BlockContentProps> = ({ blocks, className }) => {
  if (!blocks) return null

  return (
    <div className={cx(className, 'block-content px-4 md:px-0 prose marker:text-primary')}>
      <PortableText value={blocks} components={componentSerializer} />
    </div>
  )
}

export default BlockContent
