import { FC, ReactNode } from 'react'
import clsx from 'clsx'

export interface SectionContentProps {
  className?: string
  children: ReactNode
}

const SectionContent: FC<SectionContentProps> = ({ children, className }) => {
  return (
    <div className={clsx('mx-auto max-w-sm px-4 sm:max-w-lg sm:px-6 lg:max-w-3xl lg:px-8', className)}>{children}</div>
  )
}

export default SectionContent
